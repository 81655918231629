import { gql } from 'apollo-boost';

const createJobGQL = gql`
  mutation createOneJob($jobInput: CreateJobInput!) {
    createJob(jobInput: $jobInput) {
      ... on Job {
        status
        title
        benefits
        category
        company {
          name
          url
        }
        createdAt
        updatedAt
        id
        location {
          city
          country
        }
        description
        salary
        skillRequirements
        type
        workExperience
        editingCompany
      }
      ... on Failure {
        status
        error
      }
    }
  }
`;

export default createJobGQL;
