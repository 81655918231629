import { gql } from 'apollo-boost';

const deleteJobGQL = gql`
  mutation deleteOneJob($id: String!) {
    deleteJob(id: $id) {
      ... on Job {
        id
      }
      ... on Failure {
        status
        error
      }
    }
  }
`;

export default deleteJobGQL;
