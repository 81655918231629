import { gql } from 'apollo-boost';

const updateJobGQL = gql`
  mutation updateOneJob($jobInput: UpdateJobInput!) {
    updateJob(jobInput: $jobInput) {
      ... on Job {
        status
        title
        benefits
        company {
          name
          url
        }
        createdAt
        updatedAt
        id
        location {
          city
          country
        }
        description
        salary
        skillRequirements
        type
        workExperience
        editingCompany
      }
      ... on Failure {
        status
        error
      }
    }
  }
`;

export default updateJobGQL;
